import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { AvailableHeightBox } from '@fingo/lib/components/boxes';
import { useTheme } from '@mui/styles';
import MartinaNavbar from './MartinaNavbar';

const Layout = ({ children }) => {
  const theme = useTheme();
  useEffect(() => {
    document.body.style.backgroundColor = theme.palette.gray.A200;
  }, [theme]);
  return (
    <>
      <MartinaNavbar />
      <AvailableHeightBox>
        {children}
      </AvailableHeightBox>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.element.isRequired,
};

export default Layout;
